"use client";

import { FC } from "react";
import { 
  FaEnvelope, 
  FaPhoneAlt, 
  FaHeart, 
  FaMapMarkerAlt, 
  FaRegCheckCircle,
  FaRegTimesCircle,
  FaChevronRight,
  FaCode,
  FaFacebook,
  FaInstagram,
  FaTwitter
} from "react-icons/fa";

const Footer: FC = () => {
  const currentYear: number = new Date().getFullYear();
  const workingDays = [
    { day: "Monday", hours: "8:00 AM - 2:00 PM", open: true },
    { day: "Tuesday", hours: "8:00 AM - 2:00 PM", open: true },
    { day: "Wednesday", hours: "8:00 AM - 2:00 PM", open: true },
    { day: "Thursday", hours: "8:00 AM - 2:00 PM", open: true },
    { day: "Friday", hours: "8:00 AM - 2:00 PM", open: true },
    { day: "Saturday", hours: "Closed", open: false },
    { day: "Sunday", hours: "Closed", open: false },
  ];

  const mapsUrl = "https://www.google.com/maps/search/?api=1&query=Alberta+Counselling+Services+317+37+Ave+NE+%23209+Calgary+AB+T2E+6P6";

  return (
    <footer className="bg-slate-950 text-slate-100 border-t border-emerald-500/20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 py-12">
          
          {/* Contact Column */}
          <div className="space-y-8">
            <div className="max-w-sm">
              <div className="flex items-center gap-4 mb-8">
                <div className="p-3 bg-emerald-500/10 rounded-xl backdrop-blur-sm">
                  <FaHeart className="text-3xl text-emerald-400" />
                </div>
                <div>
                  <h2 className="text-xl font-bold bg-gradient-to-r from-emerald-400 to-cyan-400 bg-clip-text text-transparent">
                    Alberta Counselling Services
                  </h2>
                  <p className="text-xs text-emerald-400/90 mt-1">EST. 2004</p>
                </div>
              </div>

              <div className="space-y-4">
                <a
                  href="mailto:mbmoloho@hotmail.com"
                  className="flex items-center gap-4 p-4 rounded-xl bg-slate-800/70 hover:bg-emerald-500/10 transition-colors duration-300 group hover:ring-2 hover:ring-emerald-500/20"
                >
                  <div className="p-3 bg-emerald-500/10 rounded-lg flex-shrink-0">
                    <FaEnvelope className="text-2xl text-emerald-400" />
                  </div>
                  <div className="flex-1">
                    <p className="text-sm text-slate-300 font-medium">Email Address</p>
                    <p className="text-slate-100 font-medium transition-colors group-hover:text-emerald-400">
                      mbmoloho@hotmail.com
                    </p>
                  </div>
                  <FaChevronRight className="ml-2 text-slate-300 group-hover:text-emerald-400 transition-colors" />
                </a>

                <a
                  href="tel:+14032440244"
                  className="flex items-center gap-4 p-4 rounded-xl bg-slate-800/70 hover:bg-emerald-500/10 transition-colors duration-300 group hover:ring-2 hover:ring-emerald-500/20"
                >
                  <div className="p-3 bg-emerald-500/10 rounded-lg flex-shrink-0">
                    <FaPhoneAlt className="text-2xl text-emerald-400" />
                  </div>
                  <div className="flex-1">
                    <p className="text-sm text-slate-300 font-medium">Phone Number</p>
                    <p className="text-slate-100 font-medium transition-colors group-hover:text-emerald-400">
                      +1 (403) 244-0244
                    </p>
                  </div>
                  <FaChevronRight className="ml-2 text-slate-300 group-hover:text-emerald-400 transition-colors" />
                </a>

                <a
                  href={mapsUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-4 p-4 rounded-xl bg-slate-800/70 hover:bg-emerald-500/10 transition-colors duration-300 group hover:ring-2 hover:ring-emerald-500/20"
                >
                  <div className="p-3 bg-emerald-500/10 rounded-lg flex-shrink-0">
                    <FaMapMarkerAlt className="text-2xl text-emerald-400" />
                  </div>
                  <div className="flex-1">
                    <p className="text-sm text-slate-300 font-medium">Office Location</p>
                    <p className="text-slate-100 font-medium transition-colors group-hover:text-emerald-400">
                      317 37 Ave NE #209<br />
                      Calgary, AB T2E 6P6
                    </p>
                  </div>
                  <FaChevronRight className="ml-2 text-slate-300 group-hover:text-emerald-400 transition-colors" />
                </a>
              </div>
            </div>
          </div>

          {/* Hours Column */}
          <div className="space-y-8">
            <h3 className="text-lg font-semibold bg-gradient-to-r from-emerald-400 to-cyan-400 bg-clip-text text-transparent">
              Working Hours
            </h3>
            <div className="grid grid-cols-1 gap-3">
              {workingDays.map((day, index) => (
                <div 
                  key={index}
                  className="flex items-center justify-between p-4 rounded-lg bg-slate-800/70 hover:bg-emerald-500/10 transition-colors duration-300 group"
                >
                  <span className="text-sm font-medium text-slate-100">
                    {day.day}
                  </span>
                  <div className="flex items-center gap-2">
                    <span className={`text-sm ${day.open ? 'text-emerald-400' : 'text-rose-400'}`}>
                      {day.hours}
                    </span>
                    {day.open ? (
                      <FaRegCheckCircle className="text-emerald-500 text-sm" />
                    ) : (
                      <FaRegTimesCircle className="text-rose-500 text-sm" />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Social Media */}
        <div className="py-4">
          <div className="flex justify-center gap-6">
            <a 
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-slate-300 hover:text-emerald-400 transition-colors duration-300 p-2 rounded-full hover:bg-emerald-500/10 flex items-center justify-center hover:scale-110"
              aria-label="Facebook"
            >
              <FaFacebook className="text-2xl" />
            </a>
            <a 
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-slate-300 hover:text-emerald-400 transition-colors duration-300 p-2 rounded-full hover:bg-emerald-500/10 flex items-center justify-center hover:scale-110"
              aria-label="Instagram"
            >
              <FaInstagram className="text-2xl" />
            </a>
            <a 
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-slate-300 hover:text-emerald-400 transition-colors duration-300 p-2 rounded-full hover:bg-emerald-500/10 flex items-center justify-center hover:scale-110"
              aria-label="Twitter"
            >
              <FaTwitter className="text-2xl" />
            </a>
          </div>
        </div>

        {/* Copyright */}
        <div className="py-4">
          <div className="flex flex-col items-center gap-4 text-center">
            <p className="text-slate-300 text-sm leading-relaxed">
              © {currentYear} Alberta Counselling Services. All rights reserved.
            </p>

            
            <div className="mt-4 flex items-center gap-2 text-slate-300 hover:text-emerald-400 transition-colors duration-300">
              <FaCode className="text-sm" />
              <a 
                href="https://zss.ca" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-xs font-mono"
              >
                DIGITAL CRAFT BY ZSS.CA
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;