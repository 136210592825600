'use client';

import Link from 'next/link';
import Image from 'next/image';
import { Disclosure } from '@headlessui/react';
import { FC, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const navigation = [
  { name: 'Home', href: '/', current: true },
  { name: 'About', href: '/about', current: false },
  { name: 'Services', href: '/services', current: false },
  { name: 'FAQ', href: '/#faq', current: false }, // Add FAQ here
  { name: 'Contact', href: '/contact', current: false },
];

const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ');

const Navbar: FC = () => {
  // Mobile menu toggle state
  const [isOpen, setIsOpen] = useState(false);

  // Detects whether the page has been scrolled
  const [isScrolled, setIsScrolled] = useState(false);

  // Scroll event handler
  const handleScroll = () => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    setIsScrolled(scrollY > 0);
  };

  // Attach the scroll listener on component mount
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Toggle mobile menu
  const toggleMenu = () => setIsOpen((prev) => !prev);

  // Close mobile menu when a link is clicked
  const closeMenu = () => setIsOpen(false);

  return (
    <Disclosure
      as="nav"
      className={`fixed top-0 left-0 right-0 z-50 transition-all ${
        isScrolled ? 'backdrop-blur-lg bg-slate-100/50' : 'bg-transparent'
      }`}
    >
      <div className="mx-auto px-2 sm:px-6 bg-slate-100/50 lg:px-8">
        <div className="relative mx-auto max-w-7xl flex h-16 items-center justify-between">
          {/* Logo Section */}
          <motion.div
            className={classNames('absolute left-3 top-3', isOpen ? 'z-50' : 'z-50')}
          >
            <Link href="/" className="flex items-center">
              <Image
                alt="Alberta Counselling Services Logo"
                src={isOpen ? '/logo.png' : '/logo.png'}
                width={200}
                height={51}
                className="h-10 w-auto"
              />
            </Link>
          </motion.div>

          {/* Desktop Menu */}
          <div className="hidden sm:flex space-x-4 ml-auto ">
            {navigation.map((item) => (
              <Link
                key={item.name}
                href={item.href}
                aria-current={item.current ? 'page' : undefined}
                className={classNames(
                  'text-emerald-900 hover:text-emerald-600',
                  'px-3 py-2 text-lg font-regular'
                )}
              >
                {item.name}
              </Link>
            ))}
          </div>

          {/* Mobile Menu Toggle Icon */}
          <div className="absolute inset-y-0 right-0 flex items-center m-3 sm:hidden">
            <button 
              onClick={toggleMenu} 
              className="relative z-50 group" 
              aria-label="Toggle navigation menu"
              aria-expanded={isOpen}
            >
              <motion.div
                className="relative w-8 h-8 flex flex-col justify-center items-center"
                initial={false}
                animate={isOpen ? "open" : "closed"}
              >
                {/* Top Line */}
                <motion.span
                  className="absolute block h-0.5 w-8"
                  style={{
                    backgroundColor: isOpen ? '#334155' : '#1e293b', // Dynamic color based on state
                  }}
                  animate={{
                    rotate: isOpen ? 45 : 0,
                    y: isOpen ? 0 : -6,
                  }}
                  transition={{ duration: 0.3, ease: [0.25, 0.8, 0.25, 1] }}
                />
                {/* Middle Line */}
                <motion.span
                  className="absolute block h-0.5 w-8"
                  style={{
                    backgroundColor: isOpen ? '#334155' : '#1e293b', // Dynamic color based on state
                  }}
                  animate={{
                    opacity: isOpen ? 0 : 1,
                  }}
                  transition={{ duration: 0.2, ease: [0.25, 0.8, 0.25, 1] }}
                />
                {/* Bottom Line */}
                <motion.span
                  className="absolute block h-0.5 w-8"
                  style={{
                    backgroundColor: isOpen ? '#334155' : '#1e293b', // Dynamic color based on state
                  }}
                  animate={{
                    rotate: isOpen ? -45 : 0,
                    y: isOpen ? 0 : 6,
                  }}
                  transition={{ duration: 0.3, ease: [0.25, 0.8, 0.25, 1] }}
                />
              </motion.div>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu Overlay */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ y: '-100%' }}
            animate={{ y: 0 }}
            exit={{ y: '-100%' }}
            transition={{ duration: 0.7, ease: [0.19, 1, 0.22, 1] }}
            className="fixed inset-0 backdrop-blur-xl bg-slate-200 text-emerald-900 flex flex-col overflow-y-auto z-40"
            style={{ height: '100vh' }}
          >
            {/* Parent container for staggered children */}
            <motion.div
              className="flex flex-col mt-32 items-start px-8 flex-grow space-y-3"
              variants={{
                hidden: { opacity: 0 },
                visible: {
                  opacity: 1,
                  transition: {
                    delay: 0.1,
                    staggerChildren: 0.2,
                  },
                },
                exit: { opacity: 0 },
              }}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              {navigation.map((item) => (
                <motion.div
                  key={item.name}
                  variants={{
                    hidden: { opacity: 0, y: 20 },
                    visible: { opacity: 1, y: 0 },
                    exit: { opacity: 0, y: 20 },
                  }}
                  transition={{ duration: 0.4 }}
                >
                  <Link
                    href={item.href}
                    onClick={closeMenu}
                    className="block text-4xl font-bold text-emerald-700 transition-colors duration-200 hover:text-emerald-500 tracking-tight"
                  >
                    {item.name}
                  </Link>
                </motion.div>
              ))}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </Disclosure>
  );
};

export default Navbar;
