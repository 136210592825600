import React, { FC, ReactNode } from "react";
import Header from "@/components/common/Header"; 
import Footer from "@/components/common/Footer";

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Header (assumes it's position: fixed or sticky in the Header component) */}
      <header>
        <Header />
      </header>
      <main
        className="flex-grow"
        aria-label="Main content"
      >
        {children}
      </main>

      {/* Footer */}
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default MainLayout;
